const CONFIG = {}; //Make this global to use all over the application

CONFIG.app              = process.env.REACT_APP_API_APP       || 'dev';
CONFIG.api_schema       = process.env.REACT_APP_API_SCHEMA    || 'http';
CONFIG.api_host         = process.env.REACT_APP_API_HOST      || 'localhost';
CONFIG.api_port         = process.env.REACT_APP_API_PORT      || '3000';
CONFIG.api_version      = process.env.REACT_APP_API_VERSION   || 'v1';

CONFIG.ui_schema        = process.env.REACT_APP_UI_SCHEMA     || 'http';
CONFIG.ui_host          = process.env.REACT_APP_UI_HOST       || 'localhost';
CONFIG.ui_port          = process.env.REACT_APP_UI_PORT       || '8888';
CONFIG.ui_home          = process.env.REACT_APP_UI_HOME       || '/';

CONFIG.ui_url           = process.env.REACT_APP_DOMAIN_URL || `${CONFIG.ui_schema}://${CONFIG.ui_host}:${CONFIG.ui_port}`
CONFIG.api_url          = `${CONFIG.api_schema}://${CONFIG.api_host}/${CONFIG.api_version}`
CONFIG.myob_client_id   = process.env.REACT_APP_MYOB_CLIENT_ID  || 'uw6gjf2f2jrpuwm8hhkh3m7r'
module.exports = CONFIG;
