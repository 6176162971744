import swal from 'sweetalert2';

export function alertMyobError(errObj) {
  console.log(errObj.response.data)
  let errorTxt = errObj.response.statusText
  if (errObj.response && errObj.response.data && errObj.response.data.Errors) {
    let errors = errObj.response.data.Errors
    for(let i=0; i<errors.length; i++){
      console.log(errors[i])
      if (errors[i].Message) errorTxt += errors[i].Message 
      if (errors[i].Details) errorTxt += errors[i].Details 
      if (errors[i].AdditionalDetails) errorTxt += errors[i].AdditionalDetails 
    }
  }
  swal(errorTxt, '', "error")
}
export function alertError(errObj) {
  errObj.response && swal(errObj.response.data.error, '', "error")
}

export function alertErrorText(text) {
  swal(text, '', "error")
}

export function alertSuccess(text) {
  swal({ type: "success", timer: 2000, title: text, showConfirmButton: false });
}

export const alertSuccessWithConfirmaiton = async (title, text) => {
  return await swal({ title, type: "info", text, showConfirmButton: true, showCancelButton: true });
}

export const alertSuccessWithOk = async (title, text) => {
  return await swal({ title, type: "success", text, showConfirmButton: true });
}

// export const alertCopyLink = async (title, link) => {
//   return await swal({
//     title,
//     type: "success",
//     html: `<div class="copy-to-buffer"><span>${link}</span></div>`,
//     showConfirmButton: true,
//     onOpen() {
//       const el = document.querySelector('.copy-to-buffer');
//       el.addEventListener('click', () => {
//         copyToClipboard(link);
//         el.classList.add('copied');
//       });
//     }
//   });
// }

export const emailMe = async (title, text) => {
  return await swal({ title,
    type: "success",
    text,
    input: 'email',
    inputAttributes: {
      autocapitalize: 'off',
      required: true
    },
    inputPlaceholder: 'Your email',
    allowEnterKey: true,
    confirmButtonText: 'Send',
    showCloseButton: true,
    showConfirmButton: true });
}

