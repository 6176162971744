import React, { Component } from "react";
import { DropdownButton, MenuItem, Row, Col, Pagination, Panel, Label } from "react-bootstrap";
import i from 'immutable';
import PropTypes from 'prop-types';

import { alertError, alertSuccess } from '../../components/alerts';
import { Loader } from '../../components/loader';
import { fetchFiles, fetchTransactions } from '../../api/files';
import { connect } from '../../store/context';
import CONFIG from '../../config/config';
import { ModalDialog } from '../../components/modal'
import api from '../../config/api';
import "./style.css";


class Files extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      limit: 10,
      showModal: false,
      selectedId: null
    };
  }

  componentDidMount = async () => {
    try {
      // const { currentPage, limit } = this.state;
      const { dispatch } = this.props;
      fetchFiles(dispatch);
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  

  

  

  getMatchesList = () => {
    const { files } = this.props;
    const { dispatch } = this.props;
    const result = [];
    console.log(files)

    if (files.length > 0) {
      files.forEach((file) => {
        
        // console.log(data)
        result.push(
          <Row className="match" key={file.Id}>
            <Col md={2}> {file.Name} </Col>
            <Col md={4}> 
             
            <a href={`/file/journals?id=${file.Id}&uri=${file.Uri}`} >File Journals</a>
            &nbsp;&nbsp;
          
            </Col>
            {/* <Col md={2}> </Col> */}
            
            {/* <Col md={2}> {this.state.getIn(['files', file.Id, 'count'])} </Col> */}
            
            {/* </Col> */}
          </Row>)
      })
    }
    
    return result;
  }

  getFileJournalList = (file) => {
    console.log('getFileJournalList')
    alert(file.Id)
  }
  

  

  handleShowModal = (itemId) => {
    this.setState({showModal: true, selectedId: itemId})
  }

  handleHideModal = () => {
    this.setState({showModal: false, selectedId: null})
  }

  render() {
    const { showModal } = this.state;
    const { loading } = this.props;
    const files = this.getMatchesList();
    // const pages = this.getPagination();

    return (
      <>
      
        <Panel className="matches-panel">
          <Panel.Heading>
            <Row className="panel-title-align">
              <Col md={2}> Name </Col>
              <Col md={4}>  </Col>
              
              
            </Row>
          </Panel.Heading>
          <Panel.Body className="matches-panel-body">
            {loading && <Loader />}
            {!loading && files.length === 0 && <Label className="no-results-found">No files found</Label>}
            {files.length > 0 && files}
          </Panel.Body>
        </Panel>
        
        {showModal ? <ModalDialog onCancel={this.handleHideModal} onConfirm={this.handleDeleteMatch} /> : ''}
      </>
    );
  }
}

const select = (state) => {
  const files = state.getIn(['files', 'list'], i.List()).toJS();
  const loading = state.getIn(['matches', 'loading'], false);

  return { files, loading };
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(Files);

Files.propTypes = {
  currentPage: PropTypes.number,
  // dispatch: PropTypes.func,
  history: PropTypes.object,
  loading: PropTypes.bool,
  matches: PropTypes.array,
  pages: PropTypes.number
};
