import Alert from 'react-s-alert';
import sound from '../../resourses/alarm.mp3';


export const alertError = (message, beep = false) => {
  const config = {
    position: 'top',
    effect: 'jelly',
    timeout: 5000
  }

  if (beep) {
    config.beep = sound;
  }

  Alert.error(String(message), config);
}

export const alertWarning = (message) => {
  Alert.warning(String(message), {
    position: 'top',
    effect: 'jelly',
    timeout: 5000
  });
}

export const alertSuccess = (message) => {
  Alert.success(String(message), {
    position: 'top',
    effect: 'jelly',
    timeout: 5000
  });
}
