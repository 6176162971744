import {
  fetchFilesBegin,
  fetchFilesSuccess,
  fetchFilesError, setFileData
} from '../actions';
import CONFIG from '../config/config';
import axiosApi from '../config/api';



export const fetchFiles = async (dispatch) => {
  try {
    dispatch(fetchFilesBegin());
    const { data } = await axiosApi.get(`${CONFIG.api_url}/user/files`);
    dispatch(fetchFilesSuccess(data));
    // fetchTransactions(dispatch, data.files)
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(fetchFilesError(error));
  }
}

