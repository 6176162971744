export const FETCH_FILES_BEGIN   = 'FETCH_FILES_BEGIN';
export const FETCH_FILES_SUCCESS = 'FETCH_FILES_SUCCESS';
export const FETCH_FILES_FAILURE = 'FETCH_FILES_FAILURE';
export const SET_FILE_DATA = 'SET_FILE_DATA'
export const SET_ACCOUNT_DATA = 'SET_ACCOUNT_DATA'
export const SET_TAX_CODES = 'SET_TAX_CODES'
export const SET_LOCATIONS = 'SET_LOCATIONS'
export const SET_CUSTOM_LISTS = 'SET_CUSTOM_LISTS'
export const SET_SUPPLIERS = 'SET_SUPPLIERS'

export const fetchFilesBegin = () => ({
  type: FETCH_FILES_BEGIN
});

export const fetchFilesSuccess = (files) => ({
  type: FETCH_FILES_SUCCESS,
  data: { files }
});

export const fetchFilesError = (error) => ({
  type: FETCH_FILES_FAILURE,
  data: { error }
});

export const setFileData = (data) => ({
  type: SET_FILE_DATA,
  data: { data }
});

export const setAccountData = (data) => ({
  type: SET_ACCOUNT_DATA,
  data: { data }
})

export const setTaxCodes = (data) => ({
  type: SET_TAX_CODES,
  data: { data }
})

export const setLocations = (data) => ({
  type: SET_LOCATIONS,
  data: { data }
})

export const setCustomLists = (data) => ({
  type: SET_CUSTOM_LISTS,
  data: { data }
})

export const setSuppliers = (data) => ({
  type: SET_SUPPLIERS,
  data: { data }
})