import React, { Component } from 'react';
import { Redirect } from 'react-router';
import {Route, Switch} from 'react-router-dom';
import decode from "jwt-decode";
import './App.css';
import Alert from 'react-s-alert';
import Header from "./components/header";
import Login from "./pages/login";

import Files from "./pages/files";
import FileJournals from "./pages/files/journals";
import CreateItem from "./pages/sales/item/create";

import NotFound from "./pages/notfound";
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/jelly.css';


class App extends Component {
  isUserAuthenticated = () => {
    const jwt = localStorage.getItem('token');

    if (!jwt) {
      return false;
    }

    jwt.replace("Bearer", "");
    const dateNow = new Date().getTime() / 1000;
    const { exp } =  decode(jwt);

    return dateNow < exp;
  }

  render() {
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) => {
          if (this.isUserAuthenticated()) {
            if (rest.path === "/users/stats/noheader") {
              return <Component {...props} />
            }

            return <><Header {...props} /><Component {...props} /></>
          }

          return <Redirect to={{ pathname: "/login", state: { from: props.location }}} />
        }
        }
      />
    );

    const Home = () => (
      <section id="page-content">
        <div className="body-content animated fadeIn">
          <h1>Home page</h1>
          (<Redirect to={{ pathname: "/files" }} />)
        </div>
      </section>
    );

    return (
      <div className="App container">
        <Switch>
          <Route path="/login" component={Login} history={this.props.history} />
          <PrivateRoute path="/" component={Home} exact />
          <PrivateRoute path="/files" component={Files} exact />
          
          <PrivateRoute path="/file/journals" component={FileJournals} exact />
          <PrivateRoute path="/create-item" component={CreateItem} exact />
          
          <PrivateRoute component={NotFound} />
        </Switch>
        <Alert stack={{limit: 1}} />
      </div>
    );
  }
}

export default App;
