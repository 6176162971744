import { Map, List, fromJS } from 'immutable';
import {
  SET_TAX_CODES,
  SET_LOCATIONS,
  SET_CUSTOM_LISTS,
  SET_SUPPLIERS
} from '../actions';
import i from 'immutable';

const initialState = Map({
});

export default function matchesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TAX_CODES:
      console.log(SET_TAX_CODES)
      state = state.setIn(['taxCodes'], fromJS(action.data.data));
      break;
    case SET_LOCATIONS:
      state = state.setIn(['locations'], fromJS(action.data.data));
      break;
    case SET_CUSTOM_LISTS:
      state = state.setIn(['customList'], fromJS(action.data.data));
      break;
    case SET_SUPPLIERS:
      state = state.setIn(['suppliers'], fromJS(action.data.data));
      break;
    default:
      return state;
  }

  return state;
}
