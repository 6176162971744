import {
    setFileData,
    setAccountData,
    setTaxCodes,
    setLocations,
    setCustomLists,
    setSuppliers
} from '../actions';
import CONFIG from '../config/config';

import { MyobApi, getMyobAccessToken} from '../config/apiMyob'
import { alertError, alertErrorText, alertMyobError, alertSuccessWithOk } from '../actions/alerts';

export const getFile = async (uri) => {
    try {
        const resResult = await MyobApi({
            method: 'get',
            url: `${CONFIG.api_url}/myob/proxy?uri=${uri}`,

        })
         return [null, resResult.data]
    } catch (error) {
        console.log(error)
        return [error, null]
    }
}

export const fetchTransactions = async (dispatch, id, uri, month) => {
    //URI/Company File GUID/GeneralLedger/JournalTransaction?$filter=JournalType eq 'Purchase' and DateOccurred ge datetime'2013-11-01' and DateOccurred le datetime'2014-03-01'
    
    const ledgerTypes = ['Sale', 'Purchase', 'CashPayment', 'General', 'CashReceipt']

    for (let v = 0; v < ledgerTypes.length; v++) {
        let dateArr = month.Period.split(',')
        let dateGt = dateArr[0]
        let dateLt = dateArr[1]
        let filter = `$filter=JournalType eq '${ledgerTypes[v]}' and DateOccurred ge datetime'${dateGt}' and DateOccurred le datetime'${dateLt}'`
        fetchJournalFromMyob(dispatch, id, uri, ledgerTypes[v], filter, month.Month)

    }
}

export const fetchJournalFromMyob = async (dispatch, id, uri, desc, filter, month=null) => {
    const link = `${uri}/GeneralLedger/JournalTransaction?${filter}`
    try {
        const resResult = await MyobApi({
            method: 'get',
            url:`${CONFIG.api_url}/myob/proxy?uri=${link}` ,

        })
        const data = { 'id': id, result: resResult.data, ledgerDesc: desc, month: month }
        dispatch(setFileData(data))
        console.log(data)
    } catch (error) {
        console.log(error)
    }
}

export const fetchGeneralJournal= async (dispatch, id, uri, desc) => {
    dispatch(setAccountData({ 'id': id, result: {}, ledgerDesc: desc}))
    let link = `${uri}/GeneralLedger/Account`;
    try {
        const resResult = await MyobApi({
            method: 'get',
            url: `${CONFIG.api_url}/myob/proxy?uri=${link}`,

        })
        const data = { 'id': id, result: resResult.data, ledgerDesc: desc}
        dispatch(setAccountData(data))
        console.log(data)
    } catch (error) {
        dispatch(setAccountData({ 'id': id, result: {}, ledgerDesc: desc}))
        alertErrorText(`Unable to fetch Accounts: ${error.response.statusText}`)
    }
} 

export const fetchTaxCodes= async (dispatch, id, uri, desc) => {
    dispatch(setTaxCodes({ 'id': id, result: {}, ledgerDesc: desc}))
    let link =  `${uri}/GeneralLedger/TaxCode`;
    try {
        const resResult = await MyobApi({
            method: 'get',
            url: `${CONFIG.api_url}/myob/proxy?uri=${link}`,

        })
        const data = { 'id': id, result: resResult.data, ledgerDesc: desc}
        dispatch(setTaxCodes(data))
        console.log(data)
    } catch (error) {
        dispatch(setTaxCodes({ 'id': id, result: {}, ledgerDesc: desc}))
        alertErrorText(`Unable to fetch Tax Codes: ${error.response.statusText}`)
    }
}

export const fetchLocations= async (dispatch, id, uri) => {
    dispatch(setLocations({ 'id': id, result: {}}))
    let link = `${uri}/Inventory/Location`;
    try {
        const resResult = await MyobApi({
            method: 'get',
            url: `${CONFIG.api_url}/myob/proxy?uri=${link}`,

        })
        const data = { 'id': id, result: resResult.data}
        dispatch(setLocations(data))
        console.log(data)
    } catch (error) {
        dispatch(setLocations({ 'id': id, result: {}}))
        alertErrorText(`Unable to fetch Locations: ${error.response.statusText}`)
    }
}

export const fetchCustomLists= async (dispatch, id, uri) => {
    dispatch(setCustomLists({ 'id': id, result: {}}))
    let link = `${uri}/Company/CustomList`;
    try {
        const resResult = await MyobApi({
            method: 'get',
            url: `${CONFIG.api_url}/myob/proxy?uri=${link}`,

        })
        const data = { 'id': id, result: resResult.data}
        dispatch(setCustomLists(data))
        console.log(data)
    } catch (error) {
        dispatch(setCustomLists({ 'id': id, result: {}}))
        alertErrorText(`Unable to fetch custom list: ${error.response.statusText}`)
    }
}

export const fetchSuppliers= async (dispatch, id, uri) => {
    dispatch(setSuppliers({ 'id': id, result: {}}))
    let link = `${uri}/Contact/Supplier/`;
    try {
        const resResult = await MyobApi({
            method: 'get',
            url: `${CONFIG.api_url}/myob/proxy?uri=${link}`,

        })
        const data = { 'id': id, result: resResult.data}
        dispatch(setSuppliers(data))
        console.log(data)
    } catch (error) {
        dispatch(setSuppliers({ 'id': id, result: {}}))
        alertErrorText(`Unable to fetch supliers list: ${error}`)
    }
}

export const createItem = async (name, uri, postData) => {
    const link = `${uri}/Inventory/Item`;
    try {
        const resResult = await MyobApi({
            method: 'post',
            url: `${CONFIG.api_url}/myob/proxy?uri=${link}`,
            data: postData

        })
        alertSuccessWithOk('Success', `Item have been added in file: ${name}`)
        return [null, true]
    } catch (error) {
        alertMyobError(error)
        return [null, false]
    }
}

export const fetchItemPriceMatrix = async (name, uri, uid) => {
    const link = `${uri}/Inventory/ItemPriceMatrix/${uid}`;
    try {
        const resResult = await MyobApi({
            method: 'get',
            url: `${CONFIG.api_url}/myob/proxy?uri=${link}`,
        })
        return [null, resResult.data]
    } catch (error) {
        alertErrorText(`Error while getting price matrix for item: ${error} `)
        return [true, null]
    }
}

export const putItemPriceMatrix = async (name, uri, postData) => {
    const link = `${uri}/Inventory/ItemPriceMatrix/${postData.UID}`;
    try {
        const resResult = await MyobApi({
            method: 'put',
            url: `${CONFIG.api_url}/myob/proxy?uri=${link}`,
            data: postData

        })
        alertSuccessWithOk('Success', `Item price matrix have been added: ${name}`)
    } catch (error) {
        alertErrorText(`Error while adding price matrix: ${error} `)
    }
}

export const fetchItems= async (uri, filter) => {
    let link = `${uri}/Inventory/Item?${filter}`;
    try {
        const resResult = await MyobApi({
            method: 'get',
            url: `${CONFIG.api_url}/myob/proxy?uri=${link}`,

        })
        return [null, resResult.data]
    } catch (error) {
        alertErrorText(`Unable to fetchItems list: ${error}`)
        return [null, null]
    }
}