  import CONFIG from '../config/config';
  import axiosApi from '../config/api';
  
  
  
  export const refreshAuth = async (dispatch) => {
    try {
      const { data } = await axiosApi.get(`${CONFIG.api_url}/myob/auth/refresh`);
      const { token } = data
      if (token) {
        //   console.log('refreshing token')
        localStorage.setItem("token", JSON.stringify(token));
      }
      // fetchTransactions(dispatch, data.files)
    } catch (error) {
      console.log(error); // eslint-disable-line no-console
    }
  }
  
  