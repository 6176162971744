import i from 'immutable';
import { combineReducers } from 'redux-immutablejs';
import files from './files';
import myob from './myob';


export const reducers = combineReducers(i.Map({
  files,
  myob
}));
