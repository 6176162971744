import { Map, List, fromJS } from 'immutable';
import {
  FETCH_FILES_BEGIN,
  FETCH_FILES_SUCCESS,
  FETCH_FILES_FAILURE,
  SET_FILE_DATA,
  SET_ACCOUNT_DATA
} from '../actions';
import i from 'immutable';

const initialState = Map({
  list: List(),
  pages: 0,
  currentPage: 1,
  limit: 10,
  searchValue: '',
  sportId: '',
  loading: false,
  error: null,
});

export default function matchesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_FILES_BEGIN:
      state = state.set('loading', true);
      break;
    case FETCH_FILES_SUCCESS:
      console.log('FECCC')
      console.log(action.data.files.file)
      state = state.set('list', fromJS(action.data.files.files));
      state = state.set('loading', false);
      break;
    case FETCH_FILES_FAILURE:
      state = state.set('error', action.error);
      state = state.set('loading', false);
      break;
    case SET_FILE_DATA:
      const type = action.data.data.ledgerDesc
      let journals = state.getIn(['journals', type], List()).toJS();
      journals.push(action.data.data)
      state = state.setIn(['journals', type], fromJS(journals)  );    
      break;
    case SET_ACCOUNT_DATA:
      state = state.setIn(['journals', 'accounts'], fromJS(action.data.data)  );    
      break;
    default:
      return state;
  }

  return state;
}
