import React from "react";
import PropTypes from 'prop-types';
import './style.css';
import spiner from './loader.svg'


export const Loader = (props) => {
  const { size = '100' } = props;

  return (
    <div className="loader">
      <img alt="Loader" src={spiner} width={size} height={size} />
    </div>);
}

Loader.propTypes = {
  size: PropTypes.string
};
