import React from "react";
import "./style.css";
import { Label } from "react-bootstrap";


const NotFound = () => {
  return (
    <section id="page-content">
      <div className="notfound-block">
        <Label className="no-results-found">Page not found!</Label>
      </div>
    </section>)
}

export default NotFound;
