import React, { Component } from "react";
import { Button, FormGroup, FormControl, ControlLabel, Label } from "react-bootstrap";
import "./style.css";
import CONFIG from '../../config/config';
import api from '../../config/api';
import { alertError } from '../../components/alerts';
import queryString from 'query-string';
import { alertErrorText } from '../../actions/alerts';
class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: ""
    };
  }

  componentDidMount = () => {
    const { location: { search } } = this.props;
    const { token, error } = queryString.parse(search);
    const { history } = this.props;
    if (error) {
      alertErrorText(error)
    } else if (token) {
      localStorage.setItem("token", JSON.stringify(token));
      window.location.assign("/files");
    }
  }

  componentDidUpdate = () => {
   
  }
  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async (event) => {
    
    event.preventDefault();

    try {
      if (this.state.email.length === 0 || this.state.password.length === 0) {
        return alertError("Please enter your email and password!");
      }

      const { email, password } = this.state;
      const { data } = await api.post(`${CONFIG.api_url}/users/login`, { email, password });
      const { role } = data.user;
      const { history } = this.props;

      if (role === 'admin') {
        localStorage.setItem("token", JSON.stringify(data.token));
        history.push('/');
      } else {
        return alertError("Access denied!");
      }

      return true;
    } catch (e) {
      return alertError(e.response.data.error);
    }
  }

  render() {
    return (
      <div className="Login">
        <Label className="login-panel-title">CommandeX Hub</Label>
          
          <div><br></br></div>

          < a href={"https://secure.myob.com/oauth2/account/authorize?client_id="+ CONFIG.myob_client_id + "&redirect_uri=" + CONFIG.api_url +"/myob/auth&response_type=code&scope=CompanyFile"} ><Button block bsSize="large" type="submit"> Login via Myob </Button></a>
      </div>
    );
  }
}

export default Login;
