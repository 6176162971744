import axios from 'axios'
import CONFIG from './config';
import decode from 'jwt-decode';


export const getUserId = () => {
  const jwt = localStorage.getItem('token');

  if (!jwt) {
    return null;
  }

  jwt.replace("Bearer", "");
  const { user_id } =  decode(jwt);

  return user_id;
}

export const getMyobAccessToken = () => {
  const jwt = localStorage.getItem('token');

  if (!jwt) {
    return null;
  }

  jwt.replace("Bearer", "");
  const { myob_access_token } =  decode(jwt);

  return myob_access_token;
}



export const axiosApi = axios.create({
  baseURL: CONFIG.api_url
});


export const setAuthHeader = (token) => {
  axiosApi.defaults.headers.common['Authorization'] = token
  
}

const token = localStorage.getItem('token');

setAuthHeader(token && token !== 'undefined' ? JSON.parse(token) : '');

export default axiosApi;




