import React, { Component } from "react";
import { Button, Col, Panel, Form, FormGroup, FormControl, Checkbox, Tabs, Tab } from "react-bootstrap";
import i from 'immutable';
import PropTypes from 'prop-types';

import { alertError, alertSuccess } from '../../../../components/alerts';
import { Loader } from '../../../../components/loader';
import { fetchFiles, fetchTransactions } from '../../../../api/files';
import { connect } from '../../../../store/context';
import { alertErrorText} from '../../../../actions/alerts';

import "./style.css";
import { 
  fetchGeneralJournal, 
  fetchTaxCodes, 
  createItem, 
  fetchLocations, 
  fetchCustomLists, 
  fetchSuppliers,
  fetchItems,
  putItemPriceMatrix,
  fetchItemPriceMatrix
} from '../../../../api/myob';


class CreateItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFile: null,
      sellThisItem: false,
      buyThisItem: false,
      Name: '',
      Number: '',
      Description: '',
      isActive: false,
      incomeAccount: '',
      expenseAccount: '',
      buyingDetails: '',
      BaseSellingPrice: 0,
      StandardCost: 0,
      buyingPrice: '',
      sellTaxCodes: '',
      IsTaxInclusive: 'incTax',
      buyingUnit: '',
      SellingUnitOfMeasure: '',
      UseDescription: false,
    };
  }

  componentDidMount = async () => {
    try {
      // const { currentPage, limit } = this.state;
      const { dispatch } = this.props;
      fetchFiles(dispatch);
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  handleSave = async (event) => {
    event.preventDefault();

    const {
      selectedFile, Name, Number, isActive, incomeAccount, sellTaxCodes,
      Description, BaseSellingPrice, IsTaxInclusive, sellThisItem,
      expenseAccount, StandardCost, BuyingUnitOfMeasure, buyTaxCodes,
      SellingUnitOfMeasure, IsBought, ItemsPerSellingUnit, CalculateSalesTaxOn,
      QuantityOnHand, QuantityCommitted, QuantityOnOrder, QuantityAvailable, DefaultSellLocation,
      IsInventoried, CurrentValue, AverageCost, CostOfSalesAccount, IncomeAccount, ExpenseAccount, AssetAccount,
      Length, Colour, Section, Per, SoldOnWeb, StockLocation, LastPurchasePrice, ItemsPerBuyingUnit,
      Supplier, SupplierItemNumber, DefaultOrderQuantity, MinimumLevelForRestockingAlert, PriceMatrix , UseDescription
    } = this.state
    const { files, dispatch, accounts, taxCodes } = this.props;
    if (!selectedFile) {
      alert('Select Myob file')
      return
    }
    let postData = {
      UseDescription,
      Number,
      Name,
      "IsActive": isActive != 'on',
      Description,
      "IsSold": sellThisItem,
      IsBought,
      IsInventoried,
      QuantityOnHand,
      QuantityCommitted,
      QuantityOnOrder,
      QuantityAvailable,
      CurrentValue,
      AverageCost,
      "CostOfSalesAccount": (CostOfSalesAccount) ? { "UID": CostOfSalesAccount } : null,
      "IncomeAccount": (IncomeAccount) ? { "UID": IncomeAccount } : null,
      "AssetAccount": (AssetAccount) ? { "UID": AssetAccount } : null,
      "ExpenseAccount": (ExpenseAccount) ? { "UID": ExpenseAccount } : null,
      "CustomList1": (Length)? {'Label': 'Custom List #1', 'Value': Length} : null,
      "CustomList2": (Colour)? {'Label': 'Custom List #2', 'Value': Colour} : null,
      "CustomList3": (Section)? {'Label': 'Custom List #3', 'Value': Section} : null,
      "CustomField1": (Per)? {'Label': 'Custom Field #1', 'Value': Per} : null,
      "CustomField2": (SoldOnWeb)? {'Label': 'Custom Field #2', 'Value': SoldOnWeb} : null,
      "CustomField3": (StockLocation)? {'Label': 'Custom Field #3', 'Value': StockLocation} : null,

    }
    if (sellThisItem) {
      postData['SellingDetails'] = {
        BaseSellingPrice,
        SellingUnitOfMeasure,
        ItemsPerSellingUnit,
        "TaxCode": {
          "UID": sellTaxCodes
        },
        "IsTaxInclusive": IsTaxInclusive == 'incTax',
        CalculateSalesTaxOn: "ActualSellingPrice"
      }
    }
    if (IsBought) {
      postData['BuyingDetails'] = {
        LastPurchasePrice,
        StandardCost,
        BuyingUnitOfMeasure,
        ItemsPerBuyingUnit,
        "TaxCode": {
          "UID": buyTaxCodes
        },
        "StandardCostTaxInclusive": true,
        "RestockingInformation": {
          MinimumLevelForRestockingAlert,
          "Supplier": (Supplier)? {"UID": Supplier} : null,
          SupplierItemNumber,
          DefaultOrderQuantity
        }
      }
    }
    if (DefaultSellLocation) {
      postData['DefaultSellLocation'] =
      {
        'UID': DefaultSellLocation
      }
    }
    console.log(postData)
    
    const [err, response] = await createItem(selectedFile.Name, selectedFile.Uri, postData)
    if (response && PriceMatrix) {
      
      const [err, response] = await fetchItems(selectedFile.Uri, `$filter=Number eq '${postData.Number}'`)
      if (!sellThisItem) {
        alertErrorText('I sell this item must be set to update price matrix')
        return
      }
      if (response && response.Count == 1) {
        this.savePriceMatrix(selectedFile.Uri, response.Items[0])
      }
    }
        
    console.log(postData)
  }

  savePriceMatrix = async (uri, item) => {
    const { QuantityOver, LevelA, LevelB, LevelC, LevelD, LevelE, LevelF } = this.state
    const {   Name, UID } = item
    const [err, matrixData] = await fetchItemPriceMatrix(Name, uri, UID)
    const { RowVersion } = matrixData
    let postData = {
      UID,
      "Item": {
        UID
      },
      "SellingPrices": [
        {
          QuantityOver,
          "Levels": {
            LevelA,
          LevelB,
          LevelC,
          LevelD,
          LevelE,
          LevelF
          }
        }
      ],
      RowVersion
    }
    await putItemPriceMatrix(Name, uri, postData)
  }

  handleShowModal = (itemId) => {
    this.setState({ showModal: true, selectedId: itemId })
  }

  handleHideModal = () => {
    this.setState({ showModal: false, selectedId: null })
  }
  handleChange = (event) => {
    // alert(event.target.value)
    this.setState({ [event.target.name]: event.target.value })
    if ([
      "expenseAccount", "IncomeAccount", "buyTaxCodes", "sellTaxCodes", 
      "DefaultSellLocation", "CostOfSalesAccount", "AssetAccount", "Supplier"
    ].includes(event.target.name)) {
      this.setState({ [event.target.name]: event.target.value.split('~')[2] })
    }
    let checkboxes = ["PriceMatrix", "IsBought","UseDescription"]
    const { files, dispatch } = this.props;
    if (event.target.name == 'files') {
      for (const i in files) {
        let file = files[i]
        if (file.Name == event.target.value) {
          this.setState({ selectedFile: file })
          fetchGeneralJournal(dispatch, file.Id, file.Uri, 'Account')
          fetchLocations(dispatch, file.Id, file.Uri)
          fetchGeneralJournal(dispatch, file.Id, file.Uri, 'Account')
          fetchTaxCodes(dispatch, file.Id, file.Uri, 'TaxCodes')
          fetchCustomLists(dispatch, file.Id, file.Uri)
          fetchSuppliers(dispatch, file.Id, file.Uri)
        }

      }
    } else if (event.target.name == 'sellThisItem' || event.target.name == 'buyThisItem' || checkboxes.includes(event.target.name)) {
      this.setState({ [event.target.name]: event.target.checked })
    } else if (event.target.name == 'isActive') {
      this.setState({ sellThisItem: event.target.checked })
    } else if (event.target.name == 'IsInventoried') {
      this.setState({ IsInventoried: event.target.checked })
    }


  }

  renderFiles = () => {
    const { files } = this.props;
    let list = []
    for (const i in files) {
      let file = files[i]
      list.push(<option value={file.Name} />)
    }
    return list
  }


  renderAccounts = () => {
    const { accounts } = this.props;
    let list = []
    if (accounts && accounts.result) {
      let items = accounts.result.Items
      for (const i in items) {
        let account = items[i]
        list.push(<option value={account.Name + '~' + account.Type + '~' + account.UID} />)
      }

    }

    return list
  }

  renderTaxCodes = () => {
    const { taxCodes } = this.props;
    let list = []
    if (taxCodes && taxCodes.result) {
      let items = taxCodes.result.Items
      for (const i in items) {
        let taxCode = items[i]
        list.push(<option value={taxCode.Code + '~' + taxCode.Type + '~' + taxCode.UID} />)
      }

    }

    return list
  }

  renderLocations = () => {
    const { locations } = this.props;
    let list = []
    if (locations && locations.result) {
      let items = locations.result.Items
      for (const i in items) {
        let location = items[i]
        list.push(<option value={location.Name + '~' + location.Identifier + '~' + location.UID} />)
      }

    }

    return list
  }

  renderCustomLists = (name) => {
    const { customList } = this.props
    let list = []
    if (customList && customList.result && customList.result.Items) {
      let Values = []
      if (name == 'Length') {
        Values = customList.result.Items[0].Values
      } else if (name == 'Colour') {
        Values = customList.result.Items[1].Values
      } else if (name == 'Section') {
        Values = customList.result.Items[2].Values
      }
      for (const i in Values) {
        list.push(<option value={Values[i].Value} />)
      }

    }
    return list

  }

  renderSuppliers = (name) => {
    const { suppliers } = this.props;
    let list = []
    if (suppliers && suppliers.result) {
      let supplier = suppliers.result.Items
      for (const i in supplier) {
        let location = supplier[i]
        list.push(<option value={location.CompanyName + '~' + location.Identifier + '~' + location.UID} />)
      }

    }

    return list

  }

  checkIfChecked = (sellThisItem,IsBought,IsInventoried) => {
    //un comment if u want buttons
    //return !IsBought && !IsInventoried && sellThisItem;
    return true;
  }

  render() {
    const { sellThisItem, IsBought, PriceMatrix, IsInventoried } = this.state;

    return (
      <>

        <Panel className="matches-panel">
          <h3>Create Item</h3>
          <br></br>
          <Form horizontal onSubmit={this.handleSubmit}>
            <Tabs defaultActiveKey="proflies" id="uncontrolled-tab-example">
              <Tab eventKey="proflies" title="Proflie">
                <br /><hr />
                <FormGroup controlId="name">
                  <br></br>
                  <Col sm={2}> Select Myob File*</Col>
                  <Col sm={4}>
                    <input className="form-control" list="files" name="files" onChange={this.handleChange} autocomplete="off" />
                    <datalist id="files">
                      {this.renderFiles()}
                    </datalist>
                  </Col>
                  <br /><hr />
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}> Name*</Col>
                  <Col sm={4}>
                    <FormControl type="text" onChange={this.handleChange} placeholder="Item name" name="Name" />
                  </Col>
                </FormGroup>

                <FormGroup controlId="description">
                  <Col sm={2}> Item Description*</Col>
                  <Col sm={4}>
                    <textarea
                      id="description"
                      onChange={this.handleChange}

                      className="form-control"
                      rows="4"
                      name="Description"
                      placeholder="Short description."

                    />
                  </Col>

                </FormGroup>
                <FormGroup controlId="name">
                  <Col sm={2}> Item ID*</Col>
                  <Col sm={4}>
                    <FormControl type="text" onChange={this.handleChange} placeholder="Item ID" name="Number" />
                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}> <label for="isActive">Inactive item</label></Col>
                  <Col sm={4}>
                    <Checkbox onChange={this.handleChange} name="isActive"></Checkbox>
                  </Col>
                </FormGroup>

                <br /><hr />

                <FormGroup controlId="name">
                  <Col sm={2}> <label for="sellThisItem"> I sell this item</label></Col>
                  <Col sm={4}>
                    <Checkbox onChange={this.handleChange} name="sellThisItem"></Checkbox>
                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}> <label for="IsBought"> I buy this item</label></Col>
                  <Col sm={4}>
                    <Checkbox onChange={this.handleChange} name="IsBought"></Checkbox>
                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}> <label for="IsInventoried"> I Inventory this item</label></Col>
                  <Col sm={4}>
                    <Checkbox onChange={this.handleChange} name="IsInventoried"></Checkbox>
                  </Col>
                </FormGroup>

                <br /><hr />
                <FormGroup controlId="name">
                  <Col sm={2}>Quantity On Hand</Col>
                  <Col sm={4}>
                    <FormControl type="text" placeholder="Quantity On Hand" name="QuantityOnHand" onChange={this.handleChange} disabled={this.checkIfChecked(sellThisItem,IsBought,IsInventoried)} />
                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}>Current Value</Col>
                  <Col sm={4}>
                    <FormControl type="text" placeholder="Current Value" name="CurrentValue" onChange={this.handleChange} disabled={this.checkIfChecked(sellThisItem,IsBought,IsInventoried)} />
                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}>Average Cost</Col>
                  <Col sm={4}>
                    <FormControl type="text" placeholder="Average Cost" name="AverageCost" onChange={this.handleChange} disabled={this.checkIfChecked(sellThisItem,IsBought,IsInventoried)} />
                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}>Quantity Committed</Col>
                  <Col sm={4}>
                    <FormControl type="text" placeholder="Quantity Committed" name="QuantityCommitted" onChange={this.handleChange} disabled={this.checkIfChecked(sellThisItem,IsBought,IsInventoried)} />
                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}>Quantity On Order</Col>
                  <Col sm={4}>
                    <FormControl type="text" placeholder="Quantity On Order" name="QuantityOnOrder" onChange={this.handleChange} disabled={this.checkIfChecked(sellThisItem,IsBought,IsInventoried)} />
                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}>Quantity Available</Col>
                  <Col sm={4}>
                    <FormControl type="text" placeholder="Quantity Available" name="QuantityAvailable" onChange={this.handleChange} disabled={this.checkIfChecked(sellThisItem,IsBought,IsInventoried)} />
                  </Col>
                </FormGroup>


                <br /><hr />

                <FormGroup controlId="name">
                  <Col sm={2}>Cost Of Sales Account*</Col>
                  <Col sm={4}>
                    <input className="form-control" list="CostOfSalesAccount" name="CostOfSalesAccount" onChange={this.handleChange} autocomplete="off" />
                    <datalist id="CostOfSalesAccount">
                      {this.renderAccounts()}
                    </datalist>

                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}>Income Account*</Col>
                  <Col sm={4}>
                    <input className="form-control" list="IncomeAccount" name="IncomeAccount" onChange={this.handleChange} autocomplete="off" />
                    <datalist id="IncomeAccount">
                      {this.renderAccounts()}
                    </datalist>
                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}>Asset Account*</Col>
                  <Col sm={4}>
                    <input className="form-control" list="AssetAccount" name="AssetAccount" onChange={this.handleChange} autocomplete="off" />
                    <datalist id="AssetAccount">
                      {this.renderAccounts()}
                    </datalist>
                  </Col>
                </FormGroup>
                <br /><hr />

              </Tab>
              <Tab eventKey="ItemDetails" title="Item Details">

                <br /><hr />
                <FormGroup controlId="name">
                  <Col sm={2}> <label for="UseDescription">Use Item Desscription on Salesand Purhcases</label></Col>
                  <Col sm={4}>
                    <Checkbox onChange={this.handleChange} name="UseDescription"></Checkbox>
                  </Col>
                </FormGroup>
                {/*  
                add image  
                add description
                name needs to be changed
                */}
                <br /><hr />

                <FormGroup controlId="name">
                  <Col sm={2}>Length*</Col>
                  <Col sm={4}>
                    <input className="form-control" list="Length" name="Length" onChange={this.handleChange} autocomplete="off" />
                    <datalist id="Length">
                      {this.renderCustomLists('Length')}
                    </datalist>
                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}>Colour*</Col>
                  <Col sm={4}>
                    <input className="form-control" list="Colour" name="Colour" onChange={this.handleChange} autocomplete="off" />
                    <datalist id="Colour">
                      {this.renderCustomLists('Colour')}
                    </datalist>
                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}>Section/Category*</Col>
                  <Col sm={4}>
                    <input className="form-control" list="Section" name="Section" onChange={this.handleChange} autocomplete="off" />
                    <datalist id="Section">
                    {this.renderCustomLists('Section')}
                    </datalist>
                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}> Per*</Col>
                  <Col sm={4}>
                    <FormControl type="text" onChange={this.handleChange} placeholder="Per" name="Per" />
                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}> Sold On Web*</Col>
                  <Col sm={4}>
                    <FormControl type="text" onChange={this.handleChange} placeholder="Sold On Web" name="SoldOnWeb" />
                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}> Stock Location*</Col>
                  <Col sm={4}>
                    <FormControl type="text" onChange={this.handleChange} placeholder="Stock Location" name="StockLocation" />
                  </Col>
                </FormGroup>

              </Tab>

              <Tab eventKey="buying" title="Buying details">
                <br></br>

                <FormGroup controlId="name">
                  <Col sm={2}> Last Purchase Price (inc tax)</Col>
                  <Col sm={4}>
                    <FormControl type="text" placeholder="Last Purchase Price" name="LastPurchasePrice" onChange={this.handleChange} disabled={!IsBought} />
                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}>Standard Cost*</Col>
                  <Col sm={4}>
                    <FormControl type="text" placeholder="Standard Cost" value="0" name="StandardCost" onChange={this.handleChange} disabled={this.checkIfChecked(sellThisItem,IsBought,IsInventoried)} />
                  </Col>
                </FormGroup>


                <FormGroup controlId="name">
                  <Col sm={2}>Buying Unit of Measure</Col>
                  <Col sm={4}>
                    <FormControl type="text" placeholder="Buying Unit Of Measure" name="BuyingUnitOfMeasure" onChange={this.handleChange} disabled={!IsBought} />
                  </Col>
                </FormGroup>


                <FormGroup controlId="name">
                  <Col sm={2}>Items Per Buying Unit*</Col>
                  <Col sm={4}>
                    <input className="form-control" list="ItemsPerBuyingUnit" name="ItemsPerBuyingUnit" onChange={this.handleChange} autocomplete="off" />
                    <datalist id="ItemsPerBuyingUnit">
                      { }
                    </datalist>
                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}> Tax code*</Col>
                  <Col sm={4}>
                    <input className="form-control" list="buyTaxCodes" name="buyTaxCodes" onChange={this.handleChange} autocomplete="off" />
                    <datalist id="buyTaxCodes">
                      {this.renderTaxCodes()}
                    </datalist>
                  </Col>
                </FormGroup>

                <br /><hr />

                <FormGroup controlId="name">
                  <Col sm={2}>Minimum Level for Restocking Alert*</Col>
                  <Col sm={4}>
                    <input className="form-control" list="MinimumLevelForRestockingAlert" name="MinimumLevelForRestockingAlert" onChange={this.handleChange} autocomplete="off" />
                    <datalist id="MinimumLevelForRestockingAlert">
                      { }
                    </datalist>
                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}>Primary Supplier for Reorders*</Col>
                  <Col sm={4}>
                    <input className="form-control" list="Supplier" name="Supplier" onChange={this.handleChange} autocomplete="off" />
                    <datalist id="Supplier">
                    {this.renderSuppliers()}
                    </datalist>
                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}>SupplierItemNumber</Col>
                  <Col sm={4}>
                    <FormControl type="text" placeholder="Supplier Item Number" name="SupplierItemNumber" onChange={this.handleChange} disabled={!IsBought} />
                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}> Default Order Quantity*</Col>
                  <Col sm={4}>
                  <FormControl type="text" placeholder="Default Order Quantity" name="DefaultOrderQuantity" onChange={this.handleChange} disabled={!IsBought} />
                  </Col>
                </FormGroup>

              </Tab>

              <Tab eventKey="selling" title="Selling details">
                <br></br>

                <FormGroup controlId="name">
                  <Col sm={2}>Base Selling price ($)*</Col>
                  <Col sm={4}>
                    <FormControl type="text" placeholder="Base Selling Price" value="0" name="BaseSellingPrice" onChange={this.handleChange} disabled={!sellThisItem} />
                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}> Number of items per selling unit</Col>
                  <Col sm={4}>
                    <FormControl type="number" placeholder="ItemsPerSellingUnit" name="ItemsPerSellingUnit" onChange={this.handleChange} disabled={!sellThisItem} />
                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}> Unit of Measure</Col>
                  <Col sm={4}>
                    <FormControl type="text" placeholder="SellingUnitOfMeasure" name="SellingUnitOfMeasure" onChange={this.handleChange} disabled={!sellThisItem} />
                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}> Tax code*</Col>
                  <Col sm={4}>
                    <input className="form-control" list="sellTaxCodes" name="sellTaxCodes" onChange={this.handleChange} autocomplete="off" />
                    <datalist id="sellTaxCodes">
                      {this.renderTaxCodes()}
                    </datalist>
                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}> </Col>
                  <Col sm={4}>
                    <input type="radio" name="IsTaxInclusive" onChange={this.handleChange} value="incTax" disabled={!sellThisItem} />
                    <label for="IsTaxInclusive">Tax inclusive</label><br></br>
                    <input type="radio" name="IsTaxInclusive" onChange={this.handleChange} value="excTax" />
                    <label for="IsTaxInclusive">Tax exclusive</label><br></br>
                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}> Calculate sales tax on</Col>
                  <Col sm={4}>
                    <FormControl componentClass="select" name="CalculateSalesTaxOn" onChange={this.handleChange}>
                      <option key={1} value="Actual Selling Price" selected="true">ActualSellingPrice</option>
                    </FormControl>

                  </Col>
                </FormGroup>

                <br /><hr />
                <FormGroup controlId="name">
                  <Col sm={2}> <label for="PriceMatrix">Add PriceMatrix (Note: I sell this item must be own to set this)</label></Col>
                  <Col sm={4}>
                    <Checkbox onChange={this.handleChange} name="PriceMatrix"></Checkbox>
                  </Col>
                </FormGroup>
                <FormGroup controlId="name">
                  <Col sm={2}>Quantity Over*</Col>
                  <Col sm={4}>
                    <FormControl type="text" value="0.0" placeholder="Quantity Over" name="QuantityOver" onChange={this.handleChange} disabled="true"/>
                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}>Level A:</Col>
                  <Col sm={4}>
                  <FormControl type="text" placeholder="LevelA" name="LevelA" onChange={this.handleChange} disabled={!PriceMatrix} />
                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}>Level B:</Col>
                  <Col sm={4}>
                  <FormControl type="text" placeholder="LevelB" name="LevelB" onChange={this.handleChange} disabled={!PriceMatrix} />
                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}>Level C:</Col>
                  <Col sm={4}>
                  <FormControl type="text" placeholder="LevelC" name="LevelC" onChange={this.handleChange} disabled={!PriceMatrix} />
                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}>Level D:</Col>
                  <Col sm={4}>
                  <FormControl type="text" placeholder="LevelD" name="LevelD" onChange={this.handleChange} disabled={!PriceMatrix} />
                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}>Level E:</Col>
                  <Col sm={4}>
                  <FormControl type="text" placeholder="LevelE" name="LevelE" onChange={this.handleChange} disabled={!PriceMatrix} />
                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}>Level F:</Col>
                  <Col sm={4}>
                  <FormControl type="text" placeholder="LevelF" name="LevelF" onChange={this.handleChange} disabled={!PriceMatrix} />
                  </Col>
                </FormGroup>
                <br /><hr />
              </Tab>

              <Tab eventKey="locationDetals" title="location detals">
                <br></br>
                <FormGroup controlId="name">
                  <Col sm={2}> DefaultSellLocation</Col>
                  <Col sm={4}>
                    <input className="form-control" list="locations" name="DefaultSellLocation" onChange={this.handleChange} autocomplete="off" />
                    <datalist id="locations">
                      {this.renderLocations()}
                    </datalist>
                  </Col>
                </FormGroup>

                <FormGroup controlId="name">
                  <Col sm={2}> Select Account for tracking sales*</Col>
                  <Col sm={4}>
                    <input className="form-control" list="incomeAccount" name="incomeAccount" onChange={this.handleChange} autocomplete="off" />
                    <datalist id="incomeAccount">
                      {this.renderAccounts()}
                    </datalist>
                  </Col>
                </FormGroup>
              </Tab>

            </Tabs>



            <Col smOffset={2} sm={4}>
              <Button className="save-button" bsStyle="success" bsSize="large" type="submit" onClick={this.handleSave}> Save </Button>
              <Button bsStyle="danger" bsSize="large" type="submit" onClick={this.handleCancel}> Cancel </Button>
            </Col>
          </Form>
        </Panel>
      </>
    );
  }
}

const select = (state) => {
  const files = state.getIn(['files', 'list'], i.List()).toJS();
  const accounts = state.getIn(['files', 'journals', 'accounts'], i.List()).toJS();
  const taxCodes = state.getIn(['myob', 'taxCodes'], i.List()).toJS();
  const locations = state.getIn(['myob', 'locations'], i.List()).toJS();
  const customList = state.getIn(['myob', 'customList'], i.List()).toJS();
  const suppliers = state.getIn(['myob', 'suppliers'], i.List()).toJS();

  return { files, accounts, taxCodes, locations, customList, suppliers };
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(CreateItem);

CreateItem.propTypes = {
  currentPage: PropTypes.number,
  // dispatch: PropTypes.func,
  history: PropTypes.object,
  loading: PropTypes.bool,
  matches: PropTypes.array,
  pages: PropTypes.number
};
