import React, { Component } from "react";
import { Navbar, Nav, NavItem, DropdownButton, MenuItem } from "react-bootstrap";
import PropTypes from 'prop-types';

import { connect } from '../../store/context';
import "./style.css";
import { refreshAuth} from '../../api/auth';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: true
    };
  }

  handleLogout = () => {
    localStorage.clear();
    const { history } = this.props;
    history.push('/login')
  }

  handleNavigate = (e) => {
    const page = e.target.innerText;
    const { history } = this.props;

    switch (page) {
      case 'Files':
        history.push('/files');
        break;
      case 'Create Item':
        history.push('/create-item');
        break;
      default:
        break;
    }
  }

  render() {
    //refresh myob token every 15 mins
    this.timerInterval = setInterval(() => refreshAuth(), 900000);
    return (
      <Navbar fluid collapseOnSelect>
        <Navbar.Header>
          <Navbar.Brand>
            CommandeX Hub
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>

        <Navbar.Collapse>
          <Nav>
            <NavItem onClick={this.handleNavigate}>Files</NavItem>
            <NavItem><DropdownButton title="Inventory">
      <MenuItem onClick={this.handleNavigate}>Create Item</MenuItem>
    </DropdownButton></NavItem>
            
          </Nav>
          
          <Nav pullRight>
            {this.state.isAuthenticated ? <NavItem onClick={this.handleLogout}>Logout</NavItem> : <NavItem href="/login">Login</NavItem>}
          </Nav>

        </Navbar.Collapse>
      </Navbar>
    );
  }
}

const select = (state) => {
  const pages = state.getIn(['odds', 'pages'], 0);
  const currentPage = state.getIn(['odds', 'currentPage'], 0);
  const limit = state.getIn(['odds', 'limit'], 0);

  return { pages, currentPage, limit };
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(Header);

Header.propTypes = {
  history: PropTypes.object
};
