import React from "react";
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import "./style.css";


export const ModalDialog = (props) => {
  const { onCancel, onConfirm } = props;

  return (
    <Modal.Dialog className="my-modal-dialog">
      <Modal.Header>
        <Modal.Title>Confirm</Modal.Title>
      </Modal.Header>

      <Modal.Body><h4>Are You Sure?</h4></Modal.Body>

      <Modal.Footer>
        <Button onClick={onConfirm} bsStyle="success"> Yes </Button>
        <Button onClick={onCancel} bsStyle="danger"> Cancel </Button>
      </Modal.Footer>
    </Modal.Dialog>
  );
}

ModalDialog.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func
};
