import React, { Component } from "react";
import { DropdownButton, MenuItem, Row, Col, Pagination, Panel, Label } from "react-bootstrap";
import i from 'immutable';
import { connect } from '../../../store/context';
import PropTypes from 'prop-types';
import CONFIG from '../../../config/config';
import api from '../../../config/api';
import { alertErrorText } from '../../../actions/alerts';
import "./style.css";
import queryString from 'query-string';
import { Loader } from '../../../components/loader';
import { fetchTransactions, getFile } from '../../../api/myob';
import files from "..";
const listOfMonths = [
  {
    'Month': 'July',
    'Period': '2020-06-30, 2020-08-01',
  },
  {
    'Month': 'Aug',
    'Period': '2020-07-31, 2020-09-01',
  },
  {
    'Month': 'Sep',
    'Period': '2020-08-31, 2020-10-01',
  },
  {
    'Month': 'Oct',
    'Period': '2020-09-30, 2020-11-01',
  },
  {
    'Month': 'Nov',
    'Period': '2020-10-31, 2020-12-01',
  },
  {
    'Month': 'Dec',
    'Period': '2020-11-30, 2021-01-01',
  },
  {
    'Month': 'Jan',
    'Period': '2020-12-31, 2021-02-01',
  },
  {
    'Month': 'Feb',
    'Period': '2021-01-31, 2021-03-01',
  },
  {
    'Month': 'Mar',
    'Period': '2021-02-28, 2021-04-01',
  },
  {
    'Month': 'Apr',
    'Period': '2021-03-31, 2021-05-01',
  },
  {
    'Month': 'May',
    'Period': '2021-04-30, 2021-06-01',
  }
]
class FileJournals extends Component {
  constructor(props) {
    super(props);

    this.state = {
      CompanyFile: {}
    };
  }

  componentDidMount = async () => {
    const { location: { search } } = this.props;
    const { id, uri } = queryString.parse(search);
    const { dispatch } = this.props;
    if (!id) {
      alertErrorText('Missing file id')
    } else {
      const [err, data] = await getFile(uri)
      if (data) {
        
        this.setState({ CompanyFile: data.CompanyFile});
        listOfMonths.forEach((month) => {
          console.log(month)
          if (data.CompanyFile.Id) {
            fetchTransactions (dispatch, data.CompanyFile.Id, data.CompanyFile.Uri, month)
          }
        })
      }

    }
    
  }

  getFilesJournals = () => {
    const { salesJournals, purchaseJournals, cashPaymentJournals, generalJournals, cashReceipt } = this.props;
    const { dispatch } = this.props;
    const { CompanyFile } = this.state;
    
    let result = []
    listOfMonths.forEach((month) => {
      let salesJournal = 'loading...'
      let purchaseJournal = 'loading...'
      let cashPaymentJournal = 'loading...'
      let generalJournal = 'loading...'
      let cashReceip = 'loading...'
      salesJournals.forEach((journal) => {
        if (journal.month == month.Month) {
          salesJournal = journal.result.Count
        }
      })
      purchaseJournals.forEach((journal) => {
        if (journal.month == month.Month) {
          purchaseJournal = journal.result.Count
        }
      })
      cashPaymentJournals.forEach((journal) => {
        if (journal.month == month.Month) {
          cashPaymentJournal = journal.result.Count
        }
      })
      generalJournals.forEach((journal) => {
        if (journal.month == month.Month) {
          generalJournal = journal.result.Count
        }
      })
      cashReceipt.forEach((journal) => {
        if (journal.month == month.Month) {
          cashReceip = journal.result.Count
        }
      })
      
      result.push(
        <Row className="match" key={month.Month}>
          <Col md={2}> {month.Month} </Col>
          <Col md={2}> {salesJournal} </Col>
          <Col md={2}> {purchaseJournal} </Col>
          <Col md={2}> {cashPaymentJournal} </Col>
          <Col md={2}> {generalJournal} </Col>
          <Col md={2}> {cashReceip} </Col>
          
        </Row>)
    })
    
    return result;
  }

  fetchTransactionsByMonth = async () => {
    // const [err, data] = await 
  }


  render() {
    // const matchFields = this.getMatchFieldsList();
    const { CompanyFile } = this.state;
    // const {Name} = CompanyFile
    const { loading, salesJournals } = this.props;
    const files = this.getFilesJournals()
    return (
      <>
      {/* <h2>{JSON.stringify(salesJournals)}</h2> */}
      <h4>{CompanyFile && CompanyFile.Name} {!CompanyFile && 'Loading...'}</h4>
      <Panel className="matches-panel">
        <Panel.Heading>
          <Row className="panel-title-align">
            
            <Col md={2}> Month </Col>
            <Col md={2}> Sale </Col>
            <Col md={2}> Purchase </Col>
            <Col md={2}> CashPayment</Col>
            <Col md={2}> General </Col>
            <Col md={2}> CashReceipt </Col>
            
            
          </Row>
        </Panel.Heading>
        <Panel.Body className="matches-panel-body">
          {loading && <Loader />}
          {!loading && files.length === 0 && <Label className="no-results-found">No files found</Label>}
          {files.length > 0 && files}
        </Panel.Body>
      </Panel>
      
      
    </>
    );
  }
}
const select = (state) => {
  const files = state.getIn(['files', 'list'], i.List()).toJS();
  
  const salesJournals = state.getIn(['files', 'journals', 'Sale'], i.List()).toJS();
  const purchaseJournals = state.getIn(['files', 'journals', 'Purchase'], i.List()).toJS();
  const cashPaymentJournals = state.getIn(['files', 'journals', 'CashPayment'], i.List()).toJS();
  const generalJournals = state.getIn(['files', 'journals', 'General'], i.List()).toJS();
  const cashReceipt = state.getIn(['files', 'journals', 'CashReceipt'], i.List()).toJS();
  const loading = state.getIn(['matches', 'loading'], false);

  return { files, loading, salesJournals, purchaseJournals, cashPaymentJournals, generalJournals, cashReceipt };
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(FileJournals);


FileJournals.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
};
