import axios from 'axios'
import CONFIG from './config';
import decode from 'jwt-decode';


export const getUserId = () => {
  const jwt = localStorage.getItem('token');

  if (!jwt) {
    return null;
  }

  jwt.replace("Bearer", "");
  const { user_id } =  decode(jwt);

  return user_id;
}

export const getMyobAccessToken = () => {
  const jwt = localStorage.getItem('token');

  if (!jwt) {
    return null;
  }

  jwt.replace("Bearer", "");
  const { myob_access_token } =  decode(jwt);

  return myob_access_token;
}



export const MyobApi = axios.create({
  headers: {
    'Authorization': `Bearer ${getMyobAccessToken()}`,
    'x-myobapi-key': CONFIG.myob_client_id,
    'Content-Type': 'application/json',
    'Accept-Encoding': 'gzip,deflate',
    'x-myobapi-version': 'v2'
  }
});




